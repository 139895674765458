const state = {
    printers: []
}

const getters = {}

const actions = {
    setPrinters ({commit}, data) {
        commit('setPrinters', data)
    }
}

const mutations = {
    setPrinters (state, printers) {
        state.printers = printers
    }
}

export default {
namespaced: true,
state,
getters,
actions,
mutations
}
  