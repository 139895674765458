import Vue from 'vue';
import Router from 'vue-router';

import Dashboard from './pages/Dashboard.vue';
import DataBase from './pages/Database/Database.vue';
import Sources from './pages/Sources/Sources.vue';
import Unity from './pages/Units/Unity.vue';
import Events from './pages/Events.vue';
import PublicsReport from './pages/Publics/PublicsReport.vue';
import PublicForm from './pages/Publics/PublicForm.vue';
import ActivationBenefits from './pages/ActivationBenefits.vue';
import CampaignForm from './pages/Performance/CampaignForm.vue';
import PerformanceErrorUsers from './pages/Performance/PerformanceErrorUsers.vue';
import AdsPerformance from './pages/Performance/AdsPerformance.vue';
import CampaignReport from './pages/Performance/CampaignReport.vue';
import CampaignDetails from './pages/Performance/CampaignDetails.vue';
import PausedCampaigns from './pages/Performance/PausedCampaigns.vue';
import ArchivedCampaigns from './pages/Performance/ArchivedCampaigns.vue';
import CashbackReport from './pages/Cashback/CashbackReport.vue';
import BenefitsReport from './pages/Benefits/BenefitsReport.vue';
import ArchivedBenefits from './pages/Benefits/ArchivedBenefits.vue';
import BenefitDetails from './pages/Benefits/BenefitDetails.vue';
import Login from './pages/Account/Login.vue';
import ForgotPassword from './pages/Account/ForgotPassword.vue';
import Account from './pages/Account/Account.vue';
import Users from './pages/Account/Users.vue';

Vue.use(Router);

export const notAllowedRoutesOlga = [
  'atendimento',
  'pedidos',
  'faturamento',
  'relatorios',
  'relatorio-pedidos',
  'relatorio-clientes',
  'pesquisa-satisfacao',
  'google-ads',
  'cadastro-google-ads',
  'google-analytics',
  'cadastro-google-analytics',
  'google-tag-manager',
  'cadastro-google-tag-manager',
  'facebook-pixel',
  'cadastro-facebook-pixel',
  'detalhes-integracao',
  'cadastro-integracao-pf',
  'cadastro-integracao-pj',
  'upload-integracao',
  'dominios',
  'produtos-cardapio',
  'forgotpassword',
  'cardapio',
  'importar-catalogo',
  'configuracoes',
  'horario',
  'localizacao',
  'regioes',
  'tempos',
  'pagamento',
  'informacoes',
  'imagens',
  'impressoras',
  'impressora',
  'signup',
  'integracoes',
  'cupons',
  'cupons-expirados',
  'resultados-cupom',
]

export default new Router({
  base: '/',
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'dashboard',
      component: Dashboard,
      meta: { needsAuth: true },
    },
    {
      path: '/fontes',
      name: 'fontes',
      component: Sources,
      meta: { needsAuth: true },
    },
    {
      path: '/unidade/:unitId',
      name: 'unidade',
      component: Unity,
      meta: { needsAuth: true },
    },
    {
      path: '/base-de-dados',
      name: 'database',
      component: DataBase,
      meta: { needsAuth: true },
    },
    {
      path: '/eventos',
      name: 'eventos',
      component: Events,
      meta: { needsAuth: true },
    },
    {
      path: '/publicos',
      name: 'publicos',
      component: PublicsReport,
      meta: { needsAuth: true },
    },
    {
      path: '/publicos/criar-publico',
      name: 'criar-publico',
      component: PublicForm,
      meta: { needsAuth: true },
    },
    {
      path: '/publicos/editar-publico/:id',
      name: 'editar-publico',
      component: PublicForm,
      meta: { needsAuth: true },
    },
    /* {
      path: '/beneficios-ativacao',
      name: 'beneficios-ativacao',
      component: ActivationBenefits,
      meta: { needsAuth: true },
    }, */
    {
      path: '/performance/campanhas',
      name: 'performance',
      component: CampaignReport,
      meta: { needsAuth: true },
    },
    {
      path: '/performance/detalhes-campanha/:id',
      name: 'detalhes-campanha',
      component: CampaignDetails,
      meta: { needsAuth: true },
    },
    {
      path: '/performance/criar-campanha',
      name: 'criar-campanha',
      component: CampaignForm,
      meta: { needsAuth: true },
    },
    {
      path: '/performance/campanhas-pausadas',
      name: 'campanhas-pausadas',
      component: PausedCampaigns,
      meta: { needsAuth: true },
    },
    {
      path: '/performance/campanhas-arquivadas',
      name: 'campanhas-arquivadas',
      component: ArchivedCampaigns,
      meta: { needsAuth: true },
    },
    {
      path: '/performance/editar-campanha/:id',
      name: 'editar-campanha',
      component: CampaignForm,
      meta: { needsAuth: true },
    },
    {
      path: '/performance-error',
      name: 'performance-error',
      component: PerformanceErrorUsers,
      meta: { needsAuth: true },
    },
    {
      path: '/performance-ads',
      name: 'performance-ads',
      component: AdsPerformance,
      meta: { needsAuth: true },
    },
    {
      path: '/cashback',
      name: 'cashback',
      component: CashbackReport,
      meta: { needsAuth: true },
    },
    {
      path: '/beneficios',
      name: 'beneficios',
      component: BenefitsReport,
      meta: { needsAuth: true },
    },
    {
      path: '/beneficios-arquivados',
      name: 'beneficios-arquivados',
      component: ArchivedBenefits,
      meta: { needsAuth: true },
    },
    {
      path: '/beneficios/detalhes/:id',
      name: 'detalhes-beneficio',
      component: BenefitDetails,
      meta: { needsAuth: true },
    },
    {
      path: '/entrar',
      name: 'login',
      component: Login,
      meta: {
        bHasNoTemplate: true,
        needsAuth: false,
      },
    },
    {
      path: '/esqueci-minha-senha',
      name: 'forgotpassword',
      component: ForgotPassword,
      meta: {
        bHasNoTemplate: true,
        needsAuth: false,
      },
    },
    {
      path: '/sair',
      name: 'logout',
      component: {},
      meta: { needsAuth: true },
    },
    {
      path: '/minha-conta',
      name: 'minha-conta',
      component: Account,
      meta: { needsAuth: true },
    },
    {
      path: '/usuarios',
      name: 'usuarios',
      component: Users,
      meta: { needsAuth: true },
    },
    { path: '*', redirect: '/' },
  ],
});

// lidando com roteamento redundante
const originalPush = Router.prototype.push;
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((error) => {
    throw new Error(error);
  });
};
