import Reports from '@/api/reports'
import Utils from '@/utils'

const state = {
    cashdesk: {}
}

const getters = {}

const actions = {
    getCashdeskPeriod ({ commit }, period) {
        return new Promise((resolve) => {
            Reports.cashDesk(period).then(result => {
                commit('setCashdesk', result)
                resolve(true)
            })
        })
    }
}

const mutations = {
    setCashdesk (state, reports) {
        const data = reports.getData([])
        if (!Utils.empty(data)) {
            data.forEach(report => {
                if (!Utils.empty(report)) {
                    if (!state.cashdesk[report.period]) {
                        state.cashdesk[report.period] = {}
                    }
                    state.cashdesk[report.period] = report
                }
            })
        }
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
