/* eslint-disable no-param-reassign */
/* eslint-disable no-shadow */
import PaymentAPI from '@/api/payment';

const state = {
  tokenConfiguration: null,
  tokenConfigurationIsActive: false,
};

const getters = {
  tokenConfiguration: (state) => {
    return state.tokenConfiguration;
  },
  tokenConfigurationIsActive: (state) => {
    return state.tokenConfiguration.active;
  },
};

const actions = {
  loadConfigurations({ commit }) {
    PaymentAPI.getJunoConfiguration().then((response) => {
      if (response.getErrors([]).length === 0) {
        const {
          credentials: { privateToken, publicToken, clientId, clientSecret },
          active,
        } = response.getData({});
        if (privateToken || publicToken || clientId || clientSecret || active)
          commit('setTokenConfiguration', {
            privateToken,
            publicToken,
            clientId,
            clientSecret,
            active,
          });
      }
    });
  },
  cleanConfigurations ({ commit }) {
    commit('setTokenConfiguration', null);
    commit('setTokenConfigurationIsActive', false);
  },
};

const mutations = {
  setTokenConfiguration(state, data) {
    state.tokenConfiguration = data;
  },
  setTokenConfigurationIsActive(state, data) {
    state.tokenConfigurationIsActive = data;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
