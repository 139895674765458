export class DelayFunction {
  timeoutId = null;

  fn = () => {};

  addDelay(delay = 1000) {
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
    }

    this.timeoutId = setTimeout(() => this.executeFunction(), delay || 1000);
  }

  executeFunction() {
    this.fn && this.fn();
  }
}
