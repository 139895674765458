import vttoService from '@/api/store';
import { EnvironmentsAPI } from '@/api/Environments';

import {
  getDone,
  verifyToPublish,
  getDonePublish,
} from '@/plugins/core/schema/onBoardEnum';
import router from '@/router';

const customStorage = require('@/plugins/customStorage').default;

const state = {
  isMobile: false,
  noTemplate: true,
  onBoardDone: customStorage().get.item('onBoardDone'),
  hasUpdate: false,
  updateLater: false,
  screenWidth: 0,
  inputFocus: false,
  footerVisible: true,
  menuOpened: false,
  scrolled: 0,
  carousel: 0,
  info: {
    working_time: {
      is_open: null,
    },
  },
  configurations: {},
  loading: false,
  deliveryAreas: {},
  hasPendingOrders: false,
  hasDrawerOpened: false,
};

const getters = {
  isOpen: (state) => {
    return state.info.working_time.is_open;
  },
  isOnBoardDone: (state) => {
    return state.onBoardDone;
  },
  info: (state) => {
    return state;
  },
  isHasUpdate: (state) => {
    return state.hasUpdate;
  },
  configurations: (state) => {
    return state.configurations;
  },
};

const actions = {
  getInfo({ commit }) {
    const session = JSON.parse(sessionStorage.getItem('vtto.store.info'));

    if (session) {
      commit('setLoading', false);
      commit('setInfo', session);
      return session;
    }

    vttoService.storeInfo().then((res) => {
      if (res.data.success) {
        sessionStorage.setItem(
          'vtto.store.info',
          JSON.stringify(res.data.data)
        );
        commit('setLoading', false);
        commit('setInfo', res.data.data);
        return res.data.data;
      }
    });
  },
  getDeliveryAreas({ commit }) {
    const session = JSON.parse(
      sessionStorage.getItem('vtto.store.deliveryAreas')
    );
    if (session) {
      commit('setLoading', false);
      return commit('setDeliveryAreas', session);
    }

    vttoService.storeDeliveryAreas().then((res) => {
      sessionStorage.setItem(
        'vtto.store.deliveryAreas',
        JSON.stringify(res.data.data)
      );
      commit('setLoading', false);
      return commit('setDeliveryAreas', res.data.data);
    });
  },
  // getIsOpen ({ commit }) {
  //   vttoService.storeIsOpen().then((response) => {
  //     commit('setIsOpen', response.getData({ is_open: false }).is_open)
  //   })
  // },
  getHasUpdate({ commit }) {
    commit('setHasUpdate');
  },
  getUpdateLater({ commit }) {
    commit('setUpdateLater');
  },
  getIsMobile({ commit }) {
    commit('setIsMobile');
  },
  getScreenWidth({ commit }) {
    commit('setScreenWidth');
  },
  async getConfigurations({ commit }, loading) {
    const response = await EnvironmentsAPI.getConfigurations();
    const configurations = response.getData({});
    commit('setConfigurations', configurations);
  },
  setScroll({ commit }) {
    commit('setScroll');
  },
  setCarousel({ commit }, info) {
    commit('setCarousel', info);
  },
  setConfigurations({ commit }, configs) {
    commit('setConfigurations', configs);
  },
  changePublishStatus({ commit }, newStatus) {
    const progress = verifyToPublish(state.configurations);
    const done = getDonePublish(progress);
    if (!done) {
      if (router.currentRoute.name !== 'configuracoes') {
        return router.push({ name: 'configuracoes' });
      }

      return;
    }

    confirm(
      `Você realmente deseja ${newStatus ? 'publicar' : 'desativar'
      } a sua loja?`,
      (response) => {
        if (response) {
          vttoService
            .storeConfiguration({
              published: newStatus,
            })
            .then((response) => {
              if (response.getErrors([]).length == 0)
                alert(StringLanguage.saveSuccess);
              commit('setConfigurations', response.getData({}));
            })
            .catch((err) => {
              alert(StringLanguage.saveError);
            });
        }
      }
    );
  },
};

const mutations = {
  setIsOpen(state, info) {
    state.info.working_time.is_open = info;
  },
  setHasUpdate(state, status) {
    state.hasUpdate = status;
  },
  setUpdateLater(state, status) {
    state.updateLater = status;
  },
  setInfo(state, info) {
    state.info = info;
  },
  setLoading(state, show) {
    state.loading = show;
  },
  setDeliveryAreas(state, info) {
    state.deliveryAreas = info;
  },
  setScroll(state) {
    state.scrolled = window.pageYOffset;
  },
  setConfigurations(state, configs) {
    state.configurations = configs;
  },
  setHasPendingOrders(state, status) {
    state.hasPendingOrders = status;
  },
  setHasDrawerOpened(state, status) {
    state.hasDrawerOpened = status;
  },
  setOnBoardDone(state, status) {
    customStorage().set('onBoardDone', status, false);
    state.onBoardDone = status;
  },
  setNoTemplate(state, status) {
    state.noTemplate = status;
  },
  toggleMenu(state) {
    state.menuOpened = !state.menuOpened;
  },
  setCarousel(state, info) {
    if (info) {
      state.carousel += info;
      return state.carousel;
    }
    state.carousel = 0;
    return state.carousel;
  },
  setIsMobile(state) {
    state.isMobile = window.innerWidth < 991;
  },
  setScreenWidth(state) {
    state.screenWidth = window.innerWidth;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
