/* eslint-disable no-param-reassign */
/* eslint-disable no-use-before-define */
/* eslint-disable no-await-in-loop */
/* eslint-disable no-restricted-syntax */
const axios = require('axios');

// eslint-disable-next-line func-names
export default async function (data, env) {
  const resultQueries = [];
  for (const q of data.query) {
    if (q.type === 'query') {
      const dataQuery = await formatQuery(q, env);
      resultQueries.push(dataQuery.data.hits);
    } else if (q.type === 'aggs') {
      const dataAggs = await formatAggs(q, env);
      resultQueries.push(dataAggs.data.aggregations);
    }
  }
  return { data: resultQueries };
}

export async function doc(index, docId) {
  delete axios.defaults.headers.common['x-api-env'];
  return axios.post(`${process.env.VUE_APP_GW_API}/sls/es`, {
    method: 'get',
    index,
    id: docId,
  });
}

function search(query, index) {
  delete axios.defaults.headers.common['x-api-env'];
  return axios.post(`${process.env.VUE_APP_GW_API}/sls/es`, {
    method: 'post',
    index,
    param: '_search',
    data: query,
  });
}

async function formatQuery(q, env) {
  const environment = {
    term: {
      environment_name: {
        value: env,
      },
    },
  };

  const sort = [
    {
      id: {
        order: 'desc',
      },
    },
  ];

  if (env !== '') q.queries.unshift(environment);

  const query = {
    from: q.from ? q.from : 0,
    size: q.size ? q.size : 15,
    sort: q.sort ? q.sort : sort,
    query: {
      bool: {
        must: q.queries,
      },
    },
  };
  return search(query, q.index);
}

async function formatAggs(q, env) {
  const environment = {
    term: {
      environment_name: {
        value: env,
      },
    },
  };

  q.queries = q.queries ? q.queries : [];
  q.queries.unshift(environment);

  const query = {
    size: 0,
    query: {
      bool: {
        must: q.queries,
      },
    },
    aggs: {},
  };

  query.aggs = q.aggs;
  return search(query, q.index);
}
