import { UsersAPI } from '@/api/Users'
import { formatCities } from '@/plugins/cities'
import { getXUserKey, getXAPIEnv } from '@/plugins/account'
import customStorage from '@/plugins/customStorage'
import AppBridge from '@/plugins/app'

const state = {
  addressInfoList: [],
  isLogged: false,
  oUser: {},
  oUserAddresses: []
}

const getters = {
  states() {
    return state => state.addressInfoList['uf']
  },

  cities() {
    return state => state.addressInfoList['cities']
  },
  districts() {
    return state => state.addressInfoList['districts']
  }
}

const actions = {
  getListAddressInfo({ commit }) {
    const session = sessionStorage.getItem('konv.account.addressInfoList')

    if (session) {
      return commit('setAddressInfoList', JSON.parse(session))
    }

    konvService.ListAddressInfo().then(res => {
      let aInfos = res.data.data
      aInfos = formatCities(aInfos)
      sessionStorage.setItem('konv.account.addressInfoList', JSON.stringify(aInfos))
      commit('setAddressInfoList', aInfos)
    })
  },

  async getUser({ commit }, bReset) {
    /*  Somente se já tiver o token */
    let sToken = getXUserKey()
    if (!sToken) {
      AppBridge.emit('clearCustomerID', {})
      AppBridge.emit('clearUserData', {})
      return false
    }

    if (bReset === true) {
      localStorage.removeItem('konv.account.oUser')
      AppBridge.emit('clearCustomerID', {})
      AppBridge.emit('clearUserData', {})
    }

    const session = customStorage().get.item('konv.account.oUser')
    if (session) {
      commit('setUser', session)
    }

    const res = await UsersAPI.me();
   
    if (res.getErrors([]).length != 0) { 
      return
    }

    const aInfos = res.getData()
    
    customStorage().set('konv.account.oUser', aInfos, true)
    AppBridge.emit('setCustomerID', aInfos.id)
    AppBridge.emit('setUserData', {
      id: aInfos.user_id,
      name: aInfos.name,
      email: aInfos.email,
      environment: aInfos.environment_id
    })
    commit('setUser', aInfos)
        
  },

  getUserAddresses({ commit }, bReset) {
    /*  Somente se já tiver o token */
    let sToken = getXUserKey()
    if (!sToken) {
      return false
    }

    if (bReset) {
      sessionStorage.removeItem('konv.account.oUserAddresses')
    }

    const session = sessionStorage.getItem('konv.account.oUserAddresses')
    if (session) {
      return commit('setUserAddresses', JSON.parse(session))
    }

    let origin = null
    if (localStorage.getItem('token')) {
      origin = 'mktplc'
    }

    konvService.ListAddresses(origin).then(res => {
      if (res.data.success === true) {
        let aInfos = res.data.data
        sessionStorage.setItem('konv.account.oUserAddresses', JSON.stringify(Object.values(aInfos), true))
        commit('setUserAddresses', aInfos)
      }
    })
  },

  loyaltybalance({ commit }, loyaltybalance) {
    commit('setLoyaltyBalance', loyaltybalance)
  },
  cleanUser({ commit }) {
    commit('setUserAddresses', {})
    sessionStorage.removeItem('konv.account.oUserAddresses')
    commit('setUser', {})
  },
  getIsLogged({ commit }) {
    // const session = localStorage.getItem('konv.account.x-user-key')
    const session = customStorage().get.item('easy.account.x-user-key')
    if (session) {
      return commit('setIsLogged', true)
    } else {
      return commit('setIsLogged', false)
    }
  }
}

const mutations = {
  setUser(state, oUser) {
    state.oUser = oUser
  },
  setLoyaltyBalance(state, loyaltybalance) {
    let user = customStorage().get.item('konv.account.oUser')
    user.loyaltybalance = loyaltybalance
    customStorage().set('konv.account.oUser', user, true)
    state.oUser = user
  },
  setUserAddresses(state, oUserAddresses) {
    state.oUserAddresses = oUserAddresses
  },
  setIsLogged(state, isLogged) {
    state.isLogged = isLogged
  },
  setAddressInfoList(state, districtList) {
    state.addressInfoList = districtList
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
