import slugify from 'slugify'
import PlaceListData from './PlaceListData.json';

export class PlaceList {

    static getStates(countrySlug, filterString = null) {
        const lowerFilterString = slugify(filterString, { lower: true });
        const country = PlaceListData.find(country => country.slug == countrySlug);
        if (!country) {
            return []
        }

        return country.states.filter((state) => !filterString || state.slug.indexOf(lowerFilterString) > -1).map(({ state, slug }) => ({ state, slug }))
    }

    static getCities(countrySlug, stateSlug, filterString = null) {
        const lowerFilterString = slugify(filterString, { lower: true });
        const country = PlaceListData.find(country => country.slug == countrySlug);
        if (!country) {
            return []
        }

        const state = country.states.find(state => state.slug == stateSlug);
        if(!state) {
            return []
        }

        console.log(state);
        return state.cities.filter((city) => !filterString || city.slug.indexOf(lowerFilterString) > -1).map(({ city, slug }) => ({ city, slug }))
    }

    static getNeighborhoods(countrySlug, stateSlug, citySlug, filterString = null) {
        const lowerFilterString = slugify(filterString, { lower: true });
        const country = PlaceListData.find(country => country.slug == countrySlug);
        if (!country) {
            return []
        }

        const state = country.states.find(state => state.slug == stateSlug);
        if(!state) {
            return []
        }
        
        const city = state.cities.find(city => city.slug == citySlug);
        if(!city) {
            return []
        }

        return city.neighborhoods.filter((neighborhood) => !filterString || neighborhood.slug.indexOf(lowerFilterString) > -1).map(({ neighborhood, slug }) => ({ neighborhood, slug }))
    }
}