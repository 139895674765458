// import vttoService from '@/api/catalog'

const state = {
  cart: [],
  currentItem: {},
  step: 0,
  steps: [],
  stage: 0,
  currentItemPrices: {}
}

const getters = {
  totalCart: (state, getter) => (ruler) => {
    let sum = 0
    if (ruler) {
      for (let i in state.cart) {
        let loyalty = false
        if (state.cart[i].loyalty_payment) {
          loyalty = true
        }
        sum += state.cart[i].product_quantity * getter.itemPrice(state.cart[i].product_prices, ruler, 1, loyalty)
      }
    }
    return sum
  },

  productPrice: state => {
    return state.currentItem
  },

  itemPrice: (state) => (item, ruler, type = 1, loyalty = false) => {
    let saldo = 0
    let max = 0

    if (ruler) {
      for (let i in item.products) {
        if (type === 1 || type === 3) {
          let amount = parseFloat(item.products[i].price)
          if (item.products[i].promo > 0) {
            amount = parseFloat(item.products[i].promo)
          }

          if (ruler.type === 'W') {
            if (!loyalty) {
              saldo += amount / item.products.length
            }
          }

          if (ruler.type === 'M') {
            if (item.products[i].price > max) {
              max = amount
            }
          }
        }

        if (item.products[i].ingredients && (type === 1 || type === 2)) {
          saldo += item.products[i].ingredients
        }

        if (item.products[i].ingredientsExtra) {
          saldo += item.products[i].ingredientsExtra
        }

        if (item.products[i].infoExtra && (type === 1 || type === 2)) {
          saldo += item.products[i].infoExtra
        }

        if (item.products[i].extra && (type === 1 || type === 2)) {
          saldo += item.products[i].extra
        }
      }
      /** edge */
      if (item.edge && (type === 1 || type === 3)) {
        saldo += item.edge
      }

      if (type === 1 || type === 3) {
        saldo += max
      }
    }
    return saldo
  }
}

const actions = {
  setProduct ({ commit }, product) {
    commit('addCurrentItem', product)
  },

  addItemCart ({ commit }) {
    commit('addCart')
  },

  goSteps ({ commit }, item) {
    commit('addCurrentItem', item.item)
    commit('setStep', item.step)
    commit('setSteps', item.steps)
  },

  setSteps ({ commit }, steps) {
    commit('setSteps', steps)
  },

  cleanCurrentItem ({ commit }) {
    commit('cleanCurrentItem')
  },

  nextStep ({ commit }, i) {
    commit('nextStep', i)
  },

  setStage ({ commit }, i) {
    commit('setStage', i)
  },

  addItemsCart ({ commit }, aItems) {
    commit('addMultipleCart', aItems)
  },

  removeItemCart ({ commit }, iItemId) {
    commit('removeCart', iItemId)
  },

  updateItemCart ({ commit }, oItem) {
    commit('updateCart', oItem)
  },

  cleanCart ({ commit }) {
    commit('cleanCart')
  },

  setCart ({ commit }) {
    if (localStorage && !localStorage.getItem('cart')) {
      commit('setCart', [])
      return
    }
    if (localStorage && localStorage.getItem('cart')) {
      let cart = localStorage.getItem('cart')
      commit('setCart', JSON.parse(cart))
    }
  },

  setPrices ({ commit }, prices) {
    commit('addPrices', prices)
  }
}

const mutations = {
  addCurrentItem (state, product) {
    state.currentItem = Object.assign(state.currentItem, product)
  },

  /* Adiciona o item atual no carrinho */
  addCart (state) {
    let currentItem = state.currentItem
    currentItem.product_prices = state.currentItemPrices
    state.cart.push(state.currentItem)
    localStorage.setItem('cart', JSON.stringify(state.cart))
    state.currentItem = {}
    state.currentItemPrices = {}
  },

  setCart (state, cart) {
    state.cart = cart
    localStorage.setItem('cart', JSON.stringify(state.cart))
  },

  cleanCurrentItem (state) {
    state.currentItem = {}
    state.currentItemPrices = {}
  },

  setStep (state, step) {
    state.step = step
  },

  setSteps (state, steps) {
    if (steps) {
      state.steps = steps
    }
  },

  nextStep (state, i) {
    if (state.steps[i]) {
      state.steps[i].step += 1
    }
  },

  setStage (state, i) {
    state.stage = i
  },

  /* Adiciona uma lista de itens no carrinho */
  addMultipleCart (state, aItems) {
    state.cart = state.cart.concat(aItems)
    localStorage.setItem('cart', JSON.stringify(state.cart))
    state.currentItem = {}
  },

  /* Limpa o carrinho */
  cleanCart (state) {
    state.cart = []
    localStorage.setItem('cart', JSON.stringify(state.cart))
    state.currentItem = {}
  },

  /* Remove Item do carrinho */
  removeCart (state, iItemId) {
    state.cart.splice(iItemId, 1)
    localStorage.setItem('cart', JSON.stringify(state.cart))
  },

  /* Atualiza Item do carrinho */
  updateCart (state, oItem) {
    state.cart[oItem.id] = oItem
    localStorage.setItem('cart', JSON.stringify(state.cart))
  },

  addPrices (state, prices) {
    state.currentItemPrices = prices
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
