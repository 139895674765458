const messages = {
  'pt-BR': {
    precision: 2,
    currencyPrefix: 'R$ ',
    currencySufix: '',
    decimalSeparator: ',',
    thousandSeparator: '.',
    zipCodeMask: '##.###-###',
    dddMask: ['##'],
    phoneMask: ['(##) ####-####', '(##) #####-####'],
    phoneWithoutDdd: "#####-####",
    dateMask: '##/##/####',
    timeMask: '##:##',
    shapeDeliveryTime: '### min',
    saveError: 'Ocorreu um erro ao salvar!',
    saveSuccess: 'Salvo com sucesso!',
    noChanges: 'Nenhuma alteração realizada!',
    loadError: 'Ocorreu um erro ao carregar as informações!',
    confirmDisassociateUser: 'Você realmente deseja remover esse usuário?',
    confirmUpdateUser: 'Você realmente deseja alterar esse usuário?',
    confirmDelete: 'Você realmente deseja excluir esse item?',
    confirmCencel: 'Tem certeza que deseja cancelar este pedido?',
    accessData: 'Dados de acesso',
    userInformation: 'Informações do usuário',
    linkUser: 'Criar usuário',
    removeUser: 'Remover usuário',
    editUser: 'Editar usuário',
    editOwnUserError:
      'Para editar seu próprio usuário acesse a página "Minha conta"',
    unlinkOwnUserError: 'Não é possível remover seu próprio usuário',
    editUserMasterError: 'Não é possível editar usuários deste grupo',
    passwordError: 'As senhas inseridas não coincidem',
    administrator: 'Administrador',
    master: 'Master',
    user: 'Usuário',
    users: 'Usuários',
    nobodyLinked: 'Nenhum usuário vinculado',
    unknown: 'Desconhecido',
    employee: 'Atendente',
    name: 'Nome',
    entireName: 'Nome completo',
    email: 'E-mail',
    permission: 'Permissão',
    cellphone: 'Celular',
    phone: 'Telefone',
    password: 'Senha',
    retypePassword: 'Confirme a senha',
    simple: 'Simples',
    pizza: 'Pizza',
    step: 'Etapa',
    steps: 'Etapas',
    size: 'Tamanho',
    sizes: 'Tamanhos',
    maxFlavors: 'Máx. sabores',
    delete: 'Excluir',
    cancel: 'Cancelar',
    remove: 'Remover',
    add: 'Adicionar',
    newEdge: 'Nova Borda',
    newCategory: 'Adicionar categoria',
    edgeOf: 'Borda de',
    edgeName: 'Nome da borda',
    additionalOf: 'Adicional de',
    save: 'Salvar',
    edit: 'Editar',
    create: 'Criar',
    product: 'Produto',
    addProduct: 'Adicionar produto',
    category: 'Categoria',
    createCategory: 'Criar categoria',
    categoryName: 'Nome da categoria',
    extraIngredientName: 'Nome do extra',
    extraIngredient: 'Ingrediente extra',
    newExtraIngredient: 'Novo extra',
    extras: 'Extras',
    value: 'Valor',
    promo: 'Promoção',
    values: 'Valores',
    confirm: 'Confirmar',
    warningCategoryMessage:
      'Ao excluir a categoria {category} você perderá todas as configurações, e produtos cadastrados nela. Não sendo possível recupera-los após isso.',
    warningProductMessage:
      'Ao excluir o produto {product} você perderá todas as configurações. Não sendo possível recupera-las após isso.',
    infoAboutCategory: {
      drink: 'Categoria para bebidas, com tipos e tamanhos específicos.',
      pizza:
        'Categoria com tamanhos de pizzas, permitindo divisão de sabores, extras e bordas.',
      step: 'Categoria com montagem de itens ou combos.',
    },
    ingredients: 'Ingredientes',
    description: 'Descrição',
    productName: 'Nome do produto',
    highlightProduct: 'Destacar este produto na loja',
    hideProduct: 'Ocultar este produto na loja',
    hideCategory: 'Ocultar esta categoria na loja',
    hide: 'Ocultar',
    show: 'Exibir',
    createProduct: 'Criar produto',
    sort: 'Ordenar',
    discard: 'Descartar',
    saveSort: 'Salvar ordenação',
    search: 'Pesquisar',
    closeSearch: 'Fechar pesquisa',
    catalog: 'Cardápio',
    registeredProduct: 'produto cadastrado',
    registeredProducts: 'produtos cadastrados',
    removeLast: 'Remover último',
    from: 'De',
    to: 'Até',
    radiusError: 'Você precisa informar o tamanho do raio corretamente.',
    minOrder: 'Pedido mínimo',
    configurations: 'Configurações',
    pendingSettings:
      'Conclua as configurações necessárias, para poder solicitar a publicação da loja',
    deliveryRegions: 'Regiões de Entrega',
    birthdate: 'Data de nascimento',
    question: 'Pergunta',
    answer: 'Resposta',
    addStep: 'Adicionar etapa',
    addAnswer: 'Adicionar resposta em texto',
    copy: 'Copiar',
    copyProductNames: 'Utilizar produtos como respostas',
    aboutCopyProductNames:
      'Este método simplesmente copia o nome dos produtos para as respostas, desta forma não há vinculo nenhum entre os dois elementos. Logo, caso hajam alterações no produto copiado, será necessário replicar essas alterações aqui.',
    selectCategory: 'Selecione a categoria',
    minToSelect: 'Mín. a selecionar',
    maxToSelect: 'Max. a selecionar',
    finish: 'Finalizar',
    finished: 'Finalizado',
    cancelled: 'Cancelado',
    inProduction: 'Em produção',
    onDelivery: 'Saiu para entrega',
    hasArrived: 'Chegou',
    edge: 'Borda',
    edges: 'Bordas',
    workingTime: 'Horários de Atendimento',
    period: 'Período',
    localization: 'Localização',
    importCatalog: 'Importar Catálogo',
    address: 'Endereço',
    number: 'Número',
    zipCode: 'CEP',
    complement: 'Complemento',
    district: 'Bairro',
    city: 'Cidade',
    state: 'Estado',
    country: 'País',
    deliveryTimes: 'Tempos de entrega',
    delivery: 'Delivery',
    minDeliveryTime: 'Tempo mín. de entrega',
    maxDeliveryTime: 'Tempo máx. de entrega',
    withdrawAtCounter: 'Retirada no balcão',
    minWithdrawAtCounterTime: 'Tempo mínimo para retirada',
    maxWithdrawAtCounterTime: 'Tempo máximo para retirada',
    payment: 'Formas de Pagamento',
    credit: 'Crédito',
    debit: 'Débito',
    voucher: 'Voucher Refeição',
    voucher2: 'Voucher Alimentação',
    others: 'Outros',
    money: 'Dinheiro',
    storeInformations: 'Informações da loja',
    phone1: 'Telefone 1',
    phone2: 'Telefone 2',
    invalidPhoneNumber: 'Telefone inválido! Verifique a quantidade de dígitos.',
    invalidCellPhoneNumber:
      'Celular inválido! Verifique a quantidade de dígitos.',
    images: 'Imagens',
    imagesStore: 'Imagens da Loja',
    logo: 'Logotipo',
    noLimitsToImage: 'Sem limitações para a imagem',
    imageIcon: 'Ícone',
    imageFavicon: 'Favicon',
    imageIconLimit: 'A resolução mínima é de 200x200 pixels (.jpg, .png)',
    imageBackground: 'Cor de fundo',
    imageQualityCanBeReduced: 'A qualidade da imagem poderá ser reduzida',
    imageCannotBeCropped:
      'Não é possível ajustar essa imagem! Tamanho mínimo atingido.',
    imageBackgroundInfo:
      'A cor selecionada será usada como background no ícone do app',
    view: 'Ver atual',
    cover: 'Capa',
    applyCover: 'Aplicar capa',
    minResolutionToCover: 'A resolução mínima permitida é de 1280x400 pixels',
    imageDatabase: 'Banco de imagens',
    banner: 'Banner',
    minResolutionToBanner: 'A resolução mínima permitida é de 512x512 pixels',
    bannerTitle: 'Título do banner',
    previewImage: 'Visualizar',
    changeImage: 'Alterar imagem',
    loadImage: 'Carregar imagem',
    socialNetworks: 'Redes sociais',
    keys: 'Chaves',
    disableFacebookLogin: 'Desabilitar login com Facebook',
    showEmptyCategoriesOnEcommerce: 'Exibir categorias fechadas na loja online',
    printers: 'Impressoras',
    printer: 'Impressora',
    addPrinter: 'Adicionar impressora',
    printerName: 'Nome da impressora',
    ecommerce: 'Loja online',
    unnamedVia: 'Via sem nome',
    clientData: 'Dados do cliente',
    products: 'Produtos',
    header: 'Cabeçalho',
    footer: 'Rodapé',
    addVia: 'Adicionar via',
    changeVia: 'Alterar via',
    categories: 'Categorias',
    configureYourVia: 'Configure sua via',
    viaName: 'Nome da via',
    firingTime: 'Momento do disparo',
    whenAcceptingOrders: 'Ao aceitar pedidos',
    whenEditingOrders: 'Ao editar pedidos',
    whenCancelingOrders: 'Ao cancelar pedidos',
    whenOrderingByPhone: 'Ao cadastrar pedidos telefone',
    whenOrderingByServiceDesk: 'Ao cadastrar pedidos balcão',
    displayedCategories: 'Categorias exibidas',
    thankYouForYourPreference: 'Agradecemos a sua preferência',
    myAccount: 'Minha conta',
    currentPassword: 'Senha atual',
    newPassword: 'Nova senha',
    repeatNewPassword: 'Repetir nova senha',
    asFrom: 'a partir de',
    change: 'Alterar',
    savePasswordChangeSuccess: 'Senha alterada com sucesso',
    goToWorkingTimes: 'Ver horários de atendimento',
    cancelPause: 'Cancelar pausa',
    pauseFor15Minutes: 'Pausar atendimento por 15 minutos',
    pauseFor1Hour: 'Pausar atendimento por 1 hora',
    pauseFor6Hours: 'Pausar atendimento por 6 horas',
    pauseFor12Hours: 'Pausar atendimento por 12 horas',
    changeCategories: 'Trocar categorias',
    addCategory: 'Adicionar categoria',
    infoAndCategoryRequired:
      'Você precisa informar o nome da loja e ao menos uma categoria.',
    store: 'Loja',
    storeAlias: 'Link da loja',
    paused: 'pausada',
    closed: 'fechada',
    opened: 'aberta',
    goToMyEcommerce: 'Ver minha loja online',
    logout: 'Sair',
    orders: 'Pedidos',
    filter: 'Filtrar',
    filterPeriod: 'Filtrar periodo',
    last6Hours: 'Útimas 6 horas',
    last12Hours: 'Útimas 12 horas',
    last7Days: 'Útimos 7 dias',
    order: 'Pedido',
    clientWithdrewAtTheCounter: 'Cliente retirou',
    observation: 'Observação',
    total: 'Total',
    payers: 'Pagantes',
    chargeFor: 'Troco para',
    finishOrder: 'Finalizar pedido',
    ReadyToPickup: 'Pronto para retirada',
    online: 'Online',
    noOrders: 'Nenhum pedido',
    in: 'em',
    production: 'Produção',
    hello: 'Olá',
    marketingAutomation: 'Marketing Automatizado',
    myCampaigns: 'Minhas campanhas',
    generalResults: 'Resultados gerais',
    manageTriggers: 'Gerenciar disparos',
    customCampaign: 'Campanha personalizada',
    customCampaignDescription:
      'Envie uma mensagem para toda sua base de clientes',
    confirmPauseCampaign: 'Deseja realmente pausar esta campanha?',
    confirmActiveCampaign: 'Deseja realmente ativar esta campanha?',
    editCampaign: 'Editar campanha',
    newCampaign: 'Nova campanha',
    restoreDefault: 'Restaurar padrão',
    confirmRestoreDefaultMessage:
      'Você realmente deseja restaurar a mensagem padrão?',
    preview: 'Pré-visualizar',
    previewMessage: 'Pré-visualizar mensagem',
    campaignName: 'Nome da campanha',
    lunch: 'Almoço',
    dinner: 'Jantar',
    selectACategory: 'Selecione uma categoria',
    selectAProduct: 'Selecione um produto',
    zeroQuantityItem: 'Há produtos sem quantidade no carrinho.',
    cartEmpty: 'Carrinho vazio',
    catalogEmpty: 'Nenhum item cadastrado para ser exibido.',
    gotoCatalog: 'Ir para o cardápio',
    confirmCloseCatalog: 'Você realmente deseja sair da edição?',
    back: 'Voltar',
    confirmClose: 'Você realmente deseja sair?',
    cashback: 'Cashback',
    winOnePointToPayed: 'Porcentagem de cashback',
    expireInNMonths: 'Expirar em quantos meses',
    expireInNDays: 'Expirar em quantos dias',
    errorEmptyName: 'Informe um nome',
    errorEmptyDay: 'Informe ao menos um dia da semana',
    errorEmptyPeriod: 'Informe ao menos um período',
    errorInvalidPeriod: 'Informe os períodos corretamente',
    errorInvalidEqualTimePeriod:
      'O horário de abertura e fechamento não podem ser iguais.',
    errorInvalidTime: 'O horário de início deve ser menor que o de fim',
    errorInvalidTimeClose:
      'O horário de fechamento deve ser maior que o de abertura.',
    errorAddTime: 'Informe o período atual corretamente para adicionar um novo',
    errorEmptyMax: 'Informe o máximo de sabores.',
    errorEmptyMaxOrSizeName:
      'Configure nome e máximo de sabores do tamanho corretamente.',
    errorZipCodeInvalid: 'CEP inválido.',
    errorAddressInvalid: 'Configure o endereço corretamente.',
    errorNumberInvalid: 'Configure o número do endereço corretamente.',
    errorCityInvalid: 'Configure a cidade corretamente.',
    errorDistrictInvalid: 'Configure o bairro corretamente.',
    errorStateInvalid: 'Configure o estado corretamente.',
    errorCountryInvalid: 'Configure o país corretamente.',
    errorGeolocationInvalid: 'Configure a localização corretamente.',
    errorGeolocationDistance:
      'A localização ajustada está muito longe do endereço selecionado.',
    errorMaxCategories: 'O máximo de categorias permitidas é 2.',
    errorMinCategories: 'É necessário ao menos uma categoria.',
    errorNameRequired: 'É necessário informar o nome.',
    confirmInfo:
      'Você não preencheu todos o campos necessários. Tem certeza que deseja sair? As alterações serão descartadas',
    confirmInfoWithoutLeaveRoute:
      'Você não preencheu todos o campos necessários.',
    confirmInfoRequired:
      'Informe um nome e ao menos uma categoria para sua loja.',
    confirmComplement:
      'Tem certeza que deseja sair? As alterações serão descartadas',
    errorMinCashbackDaysExpiration: 'Mínimo de 30 dias para expiração.',
    errorEmptyCashbackPercentage:
      'A porcetagem do cashbak deve ser preenchida.',
    errorTimeInvalid: 'É necessário colocar um tempo válido.',
    errorMaxMinDeliveryTime:
      'O tempo máximo para entrega não pode ser menor ou igual ao tempo mínimo.',
    errorMaxMinWithdrawAtCounterTime:
      'O tempo máximo para retirada não pode ser menor ou igual ao tempo mínimo.',
    username: 'Nome de usuário',
    usernameAlreadyExists: 'O nome de usuário não está disponível',
    required: 'Este campo não pode ficar sem preenchimento.',
    orderAlreadyClosed:
      'Sua ação não foi concretizada, pois este pedido não está mais em aberto.',
    orderAlreadyPostponed:
      'Sua ação não foi concretizada, pois esse pedido já foi adiado por outro usuário.',
    orderStatusAlreadyChanged: 'O status desse pedido já foi alterado.',
    deliveryOrderCreated: 'Pedido criado',
    deliveryOrderMarkAsReady: 'Pedido marcado como Pronto',
    deliveryOrderSent: 'Pedido marcado como Saiu para entrega',
    deliveryOrderDelivered: 'Pedido marcado como Entregue',
    deliveryOrderClosed: 'Pedido marcado como Finalizado',
    deliveryOrderCancelled: 'Pedido marcado como Cancelado',
    deliveryOrderNothingToShow: 'Não há nada para exibir aqui',
    foodyDeliveryOrderLimitExceeded:
      'Sua cota de pedidos diários do Foody Delivery foi atingida, logo este pedido não pode ser sincronizado com o mesmo',
    birthday: 'Data de aniversário',
    dough: 'Massa',
    drinks: 'Bebidas',
    errorSizeRequired: 'Tamanho obrigatório',
    errorDrinkGroupRequired: 'Tipo de bebida é obrigatório',
    moduleStore: 'Loja de Módulos',
    coupons: 'Cupons',
    addCoupon: 'Adicionar Cupom',
    publishCoupon: 'Divulgar Cupom',
    satisfactionSurvey: 'Pesquisa de Satisfação',
    storeBilling: 'Faturamento da Loja',
    registeredCustomers: 'Clientes cadastrados',
    productsSold: 'Produtos vendidos',
    finishedOrders: 'Pedidos Finalizados',
    integrations: 'Integrações',
    options: 'Opções',
    reports: 'Relatórios',
    customerNotAssigned: 'Cliente não designado',
    nameRestaurant: 'Nome do seu restaurante',
    emailRestaurant: 'E-mail do seu restaurante',
    phone1Restaurant: 'Telefone 1 do seu restaurante',
    phone2Restaurant: 'Telefone 2 do seu restaurante',
    descriptionRestaurant: 'Descrição do seu restaurante',
    addRegion: 'Adicionar região',
    domains: 'Domínios',
    overview: 'Visão geral',
    detailing: 'Detalhamento',
    googleAds: 'Google Ads',
    googleAnalytics: 'Google Analytics',
    googleTagManager: 'Google Tag Manager',
    facebookPixel: 'API de Conversões do Facebook',
    Performance: 'Performance',
    campaign: 'Campanhas',
    createCampaign: 'Criar campanha',
    createOverview: 'Resumo da campanha',
    campaignDetails: 'Detalhes de campanha',
    campaignEdit: 'Editar campanha',
    addDistance: 'Adicionar distância',
    saveChanges: 'Salvar alterações',
    addGoal: 'Adicionar objetivo',
    createGoal: 'Criar Objetivo',
    createGrouping: 'Criar agrupamento',
    addGrouping: 'Adicionar agrupamento',
    goal: 'Objetivo',
    grouping: 'Agrupamento',
    groupingDetails: 'Detalhes de Agrupamento',
    goalDetails: 'Detalhes de Objetivo',
    renameGrouping: 'Renomear Agrupamento',
    renameGoal: 'Renomear Objetivo',
    setTheRegion: 'Defina a região',
    searchByState: 'Buscar por estado...',
    noCities: 'Nenhuma cidade/bairro selecionada',
    setTheCities: 'Defina a(s) cidade(s)',
    searchByCity: 'Buscar por cidade...',
    noDistricts: 'Nenhum bairro selecionado',
    setTheDistricts: 'Defina o(s) bairro(s)',
    searchByDistrict: 'Buscar por bairro...',
  },
};

export class Internationalization {
  static getDataByLanguage(lang) {
    const stringLanguage = messages[lang] || messages['pt-BR'];
    return {
      ...stringLanguage,
      general: {
        conversions: {
          precision: 0,
          suffix: ' conversões',
        },
      },
      temperatureConfigs: {
        celsius: {
          precision: 0,
          suffix: ' °',
        },
      },
      precipitationConfigs: {
        millimeters: {
          precision: 0,
          suffix: ' mm',
        },
      },
      timeConfigs: {
        minutes: {
          precision: '0',
          suffix: ' min',
        },
        years: {
          precision: 0,
          suffix: ' anos',
        },
        days: {
          precision: '0',
          suffix: ' dia(s)',
        },
        days_expiration: {
          precision: '0',
          suffix: ' dia(s) de expiração de ganho',
        },
        days_spend: {
          precision: '0',
          suffix: ' dia(s) de expiração de gasto',
        },
        months: {
          precision: '0',
          suffix: ' meses',
        },
      },
      currencyConfigs: {
        decimalSeparator: stringLanguage.decimalSeparator,
        thousandSeparator: stringLanguage.thousandSeparator,
        prefix: stringLanguage.currencyPrefix,
        suffix: stringLanguage.currencySuffix,
        precision: 2,
      },
      percentConfigs: {
        decimalSeparator: stringLanguage.decimalSeparator,
        thousandSeparator: stringLanguage.thousandSeparator,
        suffix: ' %',
        precision: 2,
      },
      percentIntegerConfigs: {
        precision: '0',
        suffix: ' %',
      },
      distanceConfigs: {
        decimalSeparator: stringLanguage.decimalSeparator,
        thousandSeparator: stringLanguage.thousandSeparator,
        suffix: ' Km',
        precision: 2,
      },
    };
  }
}
