import { conformToMask } from 'vue-text-mask' 
import dayjs from 'dayjs'
var relativeTime = require('dayjs/plugin/relativeTime')
var utc = require('dayjs/plugin/utc')
dayjs.extend(relativeTime)
dayjs.extend(utc)

export default {
  nullishCoalescing(startPoint, jsonPath, defaultValue = null) {
    let props = jsonPath.split('.');
    let mainProp = startPoint;
    for(const prop of props) {
      mainProp = mainProp[prop];
      if(!mainProp) {
        return defaultValue;
      }
    }
    return mainProp
  },

  phoneMask (phoneNumber) {
    var phoneNumberMask = ['(', /\d/, /\d/, ')', ' ', ...(phoneNumber.length == 11 ? [/\d/] : []), /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]

    var conformedPhoneNumber = conformToMask(
      phoneNumber,
      phoneNumberMask
    );

    return conformedPhoneNumber.conformedValue;
  },

  convertMoney (value, prefix = 'R$', sufix = '') {
    let numberFormater = new Intl.NumberFormat('pt-BR', { minimumFractionDigits: 2 });
    return `${prefix} ${numberFormater.format((+(value)).toFixed(2))}${sufix ? ' ' + sufix : ''}`
  },

  /* Inverte duas posições em um array */
  swapArrayElements (arr, indexA, indexB) {
    var temp = arr[indexA]
    arr[indexA] = arr[indexB]
    arr[indexB] = temp
    return arr
  },

  /* Inverte dia e mês em uma data (string). Ou seja, formato BR 2 US ou US to BR */
  switchDayMonth (sDate) {
    return this.swapArrayElements(sDate.split('/'), 0, 1).join('/')
  },

  /* Converte data no formato DD/MM/AAAA para timestamp */
  date2Ts (sDate) {
    sDate = this.switchDayMonth(sDate)
    var userEnteredDate = new Date(sDate)
    return Date.UTC(userEnteredDate.getFullYear(), userEnteredDate.getMonth(), userEnteredDate.getDate(), 0, 0, 0) / 1000
  },

  /* Converte data ISO (YYYY-MM-DD) para BR (DD/MM/YYYY) */
  iso2Br (sDate) {
    return this.swapArrayElements(sDate.split('-'), 0, 2).join('/')
  },

  /* Converte data BR (DD/MM/YYYY) para ISO (YYYY-MM-DD) */
  br2Iso (sDate, input = '/', output = '-') {
    if (input === '') {
      sDate = `${sDate.slice(0, 2)}/${sDate.slice(2, 4)}/${sDate.slice(4, 8)}`
      input = '/'
    }
    
    const dateArr = sDate.split(input).reverse();
    return dateArr.join(output)
  },

  /* Converte timestamp para data no formato DD/MM/AAAA */
  ts2Date (iTimestamp, time = true) {
    let options
    if (time) {
      options = { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit' }
    } else {
      options = { year: 'numeric', month: '2-digit', day: '2-digit' }
    }
    let date = new Date(iTimestamp * 1000)
    return date.toLocaleDateString('pt-BR', options)
  },

  /* Converte formato  AAAA-MM-DD para DD/MM/AAAA */
  db2date (sDate) {
    return sDate.split('-').reverse().join('/')
  },
  /* Converte formato  2019-08-30T16:51:10.000Z para DD/MM/AAAA */
  dateParse (date, timestamp = false) {
    let ms = Date.parse(date) / 1000
    return timestamp ? ms : this.ts2Date(ms)
  },

  toMilliseconds(date) {
    const newdate = new Date(date)
    const now = new Date()
    return now.getTime() - newdate.getTime()
  },

  /* Converte um timestamp em "leitura humana" */
  millisecondsToStr (milliseconds) {
    // Tempo em milisegundos
    // var  current_time_milliseconds = new Date().getTime();

    function numberEnding (number) {
      return (number > 1) ? 's' : ''
    }

    var temp = Math.floor(milliseconds / 1000)
    var days = Math.floor((temp %= 31536000) / 86400)
    if (days) {
      return `${days} dia${numberEnding(days)} atrás`
    }
    var hours = Math.floor((temp %= 86400) / 3600)
    if (hours) {
      return `${hours} hora${numberEnding(hours)} atrás`
    }
    var minutes = Math.floor((temp %= 3600) / 60)
    if (minutes) {
      return `${minutes} minuto${numberEnding(minutes)} atrás`
    }
    var seconds = temp % 60
    if (seconds) {
      return `${seconds} segundo${numberEnding(seconds)} atrás`
    }
    return 'menos de um segundo'
  },
  empty (mixedVar) {
    // discuss at: http://phpjs.org/functions/empty
    var key
    if (mixedVar === '' || mixedVar === 0 || mixedVar === '0' || mixedVar === null || mixedVar === false || mixedVar === undefined) {
      return true
    }
    if (typeof mixedVar === 'object') {
      for (key in mixedVar) {
        return false
      }
      return true
    }
    return false
  },

  sPrice (fPrice) {
    return 'R$' + parseFloat(fPrice).toFixed(2).toString().replace('.', ',')
  },

  getDayOfWeek (iDay) {
    switch (iDay) {
      case 0:
      case 7:
        return 'Domingo'
      case 1:
        return 'Segunda-feira'
      case 2:
        return 'Terça-feira'
      case 3:
        return 'Quarta-feira'
      case 4:
        return 'Quinta-feira'
      case 5:
        return 'Sexta-feira'
      case 6:
        return 'Sábado'
    }
  },

  getMonthNames (m, pos = false) {
    m = typeof m === 'string' ? m.toLowerCase() : parseInt(m)
    switch (m) {
      case 1:
      case 'jan':
        return pos ? 0 : 'Janeiro'
      case 2:
      case 'fev':
      case 'feb':
        return pos ? 1 : 'Fevereiro'
      case 3:
      case 'mar':
        return pos ? 2 : 'Março'
      case 4:
      case 'abr': 
      case 'apr':
        return pos ? 3 : 'Abril'
      case 5:
      case 'mai':
      case 'may':
        return pos ? 4 : 'Maio'
      case 6:
      case 'jun':
        return pos ? 5 : 'Junho'
      case 7:
      case 'jul':
        return pos ? 6 : 'Julho'
      case 8:
      case 'ago':
      case 'aug':
        return pos ? 7 : 'Agosto'
      case 9:
      case 'set':
      case 'sep':
        return pos ? 8 : 'Setembro'
      case 10:
      case 'out':
      case 'oct':
        return pos ? 9 : 'Outubro'
      case 11:
      case 'nov':
        return pos ? 10 : 'Novembro'
      case 12:
      case 'dez':
      case 'dec':
        return pos ? 11 : 'Dezembro'
    }
  },

  getUnityPrice (currentItem) {
    let sum = 0
    if (currentItem) {
      Object.entries(currentItem.product_prices).map(a => {
        if (a[1].amount_promo === 0) {
          sum += a[1].amount
        } else {
          sum += a[1].amount_promo
        }
      })

      sum += this.getSteps(currentItem)
    }

    // sum += getSteps(currentItem)
    // this.total = this.qty * sum
    return sum
  },

  getSteps (currentItem) {
    let sum = 0
    if (currentItem.product_steps) {
      for (let i in currentItem.product_steps) {
        if (currentItem.product_steps[i].step_ingredients) {
          for (let y in currentItem.product_steps[i].step_ingredients) {
            sum += currentItem.product_steps[i].step_ingredients[y].ingredient_quantity * this.currentItem.product_steps[i].step_ingredients[y].ingredient_amount
          }
        }
      }
    }
    return sum
  },

  slide (toggle, element) {
    if (toggle) {
      element.classList.add('opened')
    } else if (!toggle) {
      element.classList.remove('opened')
    } else {
      console.log('Valor de slide inválido!')
    }
  },

  onlyNumbers (string) {
    return string.replace(/([^0-9])/g, '')
  },

  verifyPhone (string) {
    if (string === false) {
      return false
    }

    let phone = string.replace(/([^0-9])/g, '')
    if (phone.length === 11) {
      if (parseInt(phone[2]) === 9) {
        return true
      }
    }

    if (phone.length === 10) {
      if (phone[2] < 7) {
        return true
      }
    }

    return false
  },

  timeTo (destTimestamp) {
    const diff = destTimestamp.unix() - dayjs().unix()
    const hourDiff = (new Date(diff * 1000).toISOString().substr(11, 8).split(':'))
    if (diff >= 0) {
      if (parseInt(hourDiff[0]) == 0) {
        return `${hourDiff[1]}:${hourDiff[2]}`
      } else {
        return hourDiff.join(':')
      }
    }
  },

  slugify(str) {
    str = String(str).toString();
    str = str.replace(/^\s+|\s+$/g, ""); // trim
    str = str.toLowerCase();

    // remove accents, swap ñ for n, etc
    const swaps = {
        '0': ['°', '₀', '۰', '０'],
        '1': ['¹', '₁', '۱', '１'],
        '2': ['²', '₂', '۲', '２'],
        '3': ['³', '₃', '۳', '３'],
        '4': ['⁴', '₄', '۴', '٤', '４'],
        '5': ['⁵', '₅', '۵', '٥', '５'],
        '6': ['⁶', '₆', '۶', '٦', '６'],
        '7': ['⁷', '₇', '۷', '７'],
        '8': ['⁸', '₈', '۸', '８'],
        '9': ['⁹', '₉', '۹', '９'],
        'a': ['à', 'á', 'ả', 'ã', 'ạ', 'ă', 'ắ', 'ằ', 'ẳ', 'ẵ', 'ặ', 'â', 'ấ', 'ầ', 'ẩ', 'ẫ', 'ậ', 'ā', 'ą', 'å', 'α', 'ά', 'ἀ', 'ἁ', 'ἂ', 'ἃ', 'ἄ', 'ἅ', 'ἆ', 'ἇ', 'ᾀ', 'ᾁ', 'ᾂ', 'ᾃ', 'ᾄ', 'ᾅ', 'ᾆ', 'ᾇ', 'ὰ', 'ά', 'ᾰ', 'ᾱ', 'ᾲ', 'ᾳ', 'ᾴ', 'ᾶ', 'ᾷ', 'а', 'أ', 'အ', 'ာ', 'ါ', 'ǻ', 'ǎ', 'ª', 'ა', 'अ', 'ا', 'ａ', 'ä'],
        'b': ['б', 'β', 'ب', 'ဗ', 'ბ', 'ｂ'],
        'c': ['ç', 'ć', 'č', 'ĉ', 'ċ', 'ｃ'],
        'd': ['ď', 'ð', 'đ', 'ƌ', 'ȡ', 'ɖ', 'ɗ', 'ᵭ', 'ᶁ', 'ᶑ', 'д', 'δ', 'د', 'ض', 'ဍ', 'ဒ', 'დ', 'ｄ'],
        'e': ['é', 'è', 'ẻ', 'ẽ', 'ẹ', 'ê', 'ế', 'ề', 'ể', 'ễ', 'ệ', 'ë', 'ē', 'ę', 'ě', 'ĕ', 'ė', 'ε', 'έ', 'ἐ', 'ἑ', 'ἒ', 'ἓ', 'ἔ', 'ἕ', 'ὲ', 'έ', 'е', 'ё', 'э', 'є', 'ə', 'ဧ', 'ေ', 'ဲ', 'ე', 'ए', 'إ', 'ئ', 'ｅ'],
        'f': ['ф', 'φ', 'ف', 'ƒ', 'ფ', 'ｆ'],
        'g': ['ĝ', 'ğ', 'ġ', 'ģ', 'г', 'ґ', 'γ', 'ဂ', 'გ', 'گ', 'ｇ'],
        'h': ['ĥ', 'ħ', 'η', 'ή', 'ح', 'ه', 'ဟ', 'ှ', 'ჰ', 'ｈ'],
        'i': ['í', 'ì', 'ỉ', 'ĩ', 'ị', 'î', 'ï', 'ī', 'ĭ', 'į', 'ı', 'ι', 'ί', 'ϊ', 'ΐ', 'ἰ', 'ἱ', 'ἲ', 'ἳ', 'ἴ', 'ἵ', 'ἶ', 'ἷ', 'ὶ', 'ί', 'ῐ', 'ῑ', 'ῒ', 'ΐ', 'ῖ', 'ῗ', 'і', 'ї', 'и', 'ဣ', 'ိ', 'ီ', 'ည်', 'ǐ', 'ი', 'इ', 'ی', 'ｉ'],
        'j': ['ĵ', 'ј', 'Ј', 'ჯ', 'ج', 'ｊ'],
        'k': ['ķ', 'ĸ', 'к', 'κ', 'Ķ', 'ق', 'ك', 'က', 'კ', 'ქ', 'ک', 'ｋ'],
        'l': ['ł', 'ľ', 'ĺ', 'ļ', 'ŀ', 'л', 'λ', 'ل', 'လ', 'ლ', 'ｌ'],
        'm': ['м', 'μ', 'م', 'မ', 'მ', 'ｍ'],
        'n': ['ñ', 'ń', 'ň', 'ņ', 'ŉ', 'ŋ', 'ν', 'н', 'ن', 'န', 'ნ', 'ｎ'],
        'o': ['ó', 'ò', 'ỏ', 'õ', 'ọ', 'ô', 'ố', 'ồ', 'ổ', 'ỗ', 'ộ', 'ơ', 'ớ', 'ờ', 'ở', 'ỡ', 'ợ', 'ø', 'ō', 'ő', 'ŏ', 'ο', 'ὀ', 'ὁ', 'ὂ', 'ὃ', 'ὄ', 'ὅ', 'ὸ', 'ό', 'о', 'و', 'θ', 'ို', 'ǒ', 'ǿ', 'º', 'ო', 'ओ', 'ｏ', 'ö'],
        'p': ['п', 'π', 'ပ', 'პ', 'پ', 'ｐ'],
        'q': ['ყ', 'ｑ'],
        'r': ['ŕ', 'ř', 'ŗ', 'р', 'ρ', 'ر', 'რ', 'ｒ'],
        's': ['ś', 'š', 'ş', 'с', 'σ', 'ș', 'ς', 'س', 'ص', 'စ', 'ſ', 'ს', 'ｓ'],
        't': ['ť', 'ţ', 'т', 'τ', 'ț', 'ت', 'ط', 'ဋ', 'တ', 'ŧ', 'თ', 'ტ', 'ｔ'],
        'u': ['ú', 'ù', 'ủ', 'ũ', 'ụ', 'ư', 'ứ', 'ừ', 'ử', 'ữ', 'ự', 'û', 'ū', 'ů', 'ű', 'ŭ', 'ų', 'µ', 'у', 'ဉ', 'ု', 'ူ', 'ǔ', 'ǖ', 'ǘ', 'ǚ', 'ǜ', 'უ', 'उ', 'ｕ', 'ў', 'ü'],
        'v': ['в', 'ვ', 'ϐ', 'ｖ'],
        'w': ['ŵ', 'ω', 'ώ', 'ဝ', 'ွ', 'ｗ'],
        'x': ['χ', 'ξ', 'ｘ'],
        'y': ['ý', 'ỳ', 'ỷ', 'ỹ', 'ỵ', 'ÿ', 'ŷ', 'й', 'ы', 'υ', 'ϋ', 'ύ', 'ΰ', 'ي', 'ယ', 'ｙ'],
        'z': ['ź', 'ž', 'ż', 'з', 'ζ', 'ز', 'ဇ', 'ზ', 'ｚ'],
        'aa': ['ع', 'आ', 'آ'],
        'ae': ['æ', 'ǽ'],
        'ai': ['ऐ'],
        'ch': ['ч', 'ჩ', 'ჭ', 'چ'],
        'dj': ['ђ', 'đ'],
        'dz': ['џ', 'ძ'],
        'ei': ['ऍ'],
        'gh': ['غ', 'ღ'],
        'ii': ['ई'],
        'ij': ['ĳ'],
        'kh': ['х', 'خ', 'ხ'],
        'lj': ['љ'],
        'nj': ['њ'],
        'oe': ['ö', 'œ', 'ؤ'],
        'oi': ['ऑ'],
        'oii': ['ऒ'],
        'ps': ['ψ'],
        'sh': ['ш', 'შ', 'ش'],
        'shch': ['щ'],
        'ss': ['ß'],
        'sx': ['ŝ'],
        'th': ['þ', 'ϑ', 'ث', 'ذ', 'ظ'],
        'ts': ['ц', 'ც', 'წ'],
        'ue': ['ü'],
        'uu': ['ऊ'],
        'ya': ['я'],
        'yu': ['ю'],
        'zh': ['ж', 'ჟ', 'ژ'],
        '(c)': ['©'],
        'A': ['Á', 'À', 'Ả', 'Ã', 'Ạ', 'Ă', 'Ắ', 'Ằ', 'Ẳ', 'Ẵ', 'Ặ', 'Â', 'Ấ', 'Ầ', 'Ẩ', 'Ẫ', 'Ậ', 'Å', 'Ā', 'Ą', 'Α', 'Ά', 'Ἀ', 'Ἁ', 'Ἂ', 'Ἃ', 'Ἄ', 'Ἅ', 'Ἆ', 'Ἇ', 'ᾈ', 'ᾉ', 'ᾊ', 'ᾋ', 'ᾌ', 'ᾍ', 'ᾎ', 'ᾏ', 'Ᾰ', 'Ᾱ', 'Ὰ', 'Ά', 'ᾼ', 'А', 'Ǻ', 'Ǎ', 'Ａ', 'Ä'],
        'B': ['Б', 'Β', 'ब', 'Ｂ'],
        'C': ['Ç', 'Ć', 'Č', 'Ĉ', 'Ċ', 'Ｃ'],
        'D': ['Ď', 'Ð', 'Đ', 'Ɖ', 'Ɗ', 'Ƌ', 'ᴅ', 'ᴆ', 'Д', 'Δ', 'Ｄ'],
        'E': ['É', 'È', 'Ẻ', 'Ẽ', 'Ẹ', 'Ê', 'Ế', 'Ề', 'Ể', 'Ễ', 'Ệ', 'Ë', 'Ē', 'Ę', 'Ě', 'Ĕ', 'Ė', 'Ε', 'Έ', 'Ἐ', 'Ἑ', 'Ἒ', 'Ἓ', 'Ἔ', 'Ἕ', 'Έ', 'Ὲ', 'Е', 'Ё', 'Э', 'Є', 'Ə', 'Ｅ'],
        'F': ['Ф', 'Φ', 'Ｆ'],
        'G': ['Ğ', 'Ġ', 'Ģ', 'Г', 'Ґ', 'Γ', 'Ｇ'],
        'H': ['Η', 'Ή', 'Ħ', 'Ｈ'],
        'I': ['Í', 'Ì', 'Ỉ', 'Ĩ', 'Ị', 'Î', 'Ï', 'Ī', 'Ĭ', 'Į', 'İ', 'Ι', 'Ί', 'Ϊ', 'Ἰ', 'Ἱ', 'Ἳ', 'Ἴ', 'Ἵ', 'Ἶ', 'Ἷ', 'Ῐ', 'Ῑ', 'Ὶ', 'Ί', 'И', 'І', 'Ї', 'Ǐ', 'ϒ', 'Ｉ'],
        'J': ['Ｊ'],
        'K': ['К', 'Κ', 'Ｋ'],
        'L': ['Ĺ', 'Ł', 'Л', 'Λ', 'Ļ', 'Ľ', 'Ŀ', 'ल', 'Ｌ'],
        'M': ['М', 'Μ', 'Ｍ'],
        'N': ['Ń', 'Ñ', 'Ň', 'Ņ', 'Ŋ', 'Н', 'Ν', 'Ｎ'],
        'O': ['Ó', 'Ò', 'Ỏ', 'Õ', 'Ọ', 'Ô', 'Ố', 'Ồ', 'Ổ', 'Ỗ', 'Ộ', 'Ơ', 'Ớ', 'Ờ', 'Ở', 'Ỡ', 'Ợ', 'Ø', 'Ō', 'Ő', 'Ŏ', 'Ο', 'Ό', 'Ὀ', 'Ὁ', 'Ὂ', 'Ὃ', 'Ὄ', 'Ὅ', 'Ὸ', 'Ό', 'О', 'Θ', 'Ө', 'Ǒ', 'Ǿ', 'Ｏ', 'Ö'],
        'P': ['П', 'Π', 'Ｐ'],
        'Q': ['Ｑ'],
        'R': ['Ř', 'Ŕ', 'Р', 'Ρ', 'Ŗ', 'Ｒ'],
        'S': ['Ş', 'Ŝ', 'Ș', 'Š', 'Ś', 'С', 'Σ', 'Ｓ'],
        'T': ['Ť', 'Ţ', 'Ŧ', 'Ț', 'Т', 'Τ', 'Ｔ'],
        'U': ['Ú', 'Ù', 'Ủ', 'Ũ', 'Ụ', 'Ư', 'Ứ', 'Ừ', 'Ử', 'Ữ', 'Ự', 'Û', 'Ū', 'Ů', 'Ű', 'Ŭ', 'Ų', 'У', 'Ǔ', 'Ǖ', 'Ǘ', 'Ǚ', 'Ǜ', 'Ｕ', 'Ў', 'Ü'],
        'V': ['В', 'Ｖ'],
        'W': ['Ω', 'Ώ', 'Ŵ', 'Ｗ'],
        'X': ['Χ', 'Ξ', 'Ｘ'],
        'Y': ['Ý', 'Ỳ', 'Ỷ', 'Ỹ', 'Ỵ', 'Ÿ', 'Ῠ', 'Ῡ', 'Ὺ', 'Ύ', 'Ы', 'Й', 'Υ', 'Ϋ', 'Ŷ', 'Ｙ'],
        'Z': ['Ź', 'Ž', 'Ż', 'З', 'Ζ', 'Ｚ'],
        'AE': ['Æ', 'Ǽ'],
        'Ch': ['Ч'],
        'Dj': ['Ђ'],
        'Dz': ['Џ'],
        'Gx': ['Ĝ'],
        'Hx': ['Ĥ'],
        'Ij': ['Ĳ'],
        'Jx': ['Ĵ'],
        'Kh': ['Х'],
        'Lj': ['Љ'],
        'Nj': ['Њ'],
        'Oe': ['Œ'],
        'Ps': ['Ψ'],
        'Sh': ['Ш'],
        'Shch': ['Щ'],
        'Ss': ['ẞ'],
        'Th': ['Þ'],
        'Ts': ['Ц'],
        'Ya': ['Я'],
        'Yu': ['Ю'],
        'Zh': ['Ж'],
    };

    Object.keys(swaps).forEach((swap) => {
        swaps[swap].forEach(s => {
            str = str.replace(new RegExp(s, "g"), swap);
        })
    });
    return str
        .replace(/[^a-z0-9 -]/g, "") // remove invalid chars
        .replace(/\s+/g, "-") // collapse whitespace and replace by -
        .replace(/-+/g, "-") // collapse dashes
        .replace(/^-+/, "") // trim - from start of text
        .replace(/-+$/, "");
  },

  debounce (fn, delay) {
    let timer = null
    return function () {
      let self = this,
        args = arguments
      
      clearTimeout(timer)
      timer = setTimeout(function () {
        fn.apply(self, args)
      }, delay)
    }
  },
  capitalize (s) {
	if (typeof s !== 'string') return ''
	return s.charAt(0).toUpperCase() + s.slice(1);
  }  
}
