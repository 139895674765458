/* eslint-disable no-param-reassign */
/* eslint-disable no-shadow */
import DeliveryPlatformAPI from '@/api/deliveryPlatform';

const state = {
  deliveryPlatform: 'foody_delivery',
  tokenConfiguration: null,
  tokenConfigurationIsActive: false,
};

const getters = {
  deliveryPlatform: (state) => {
    return state.deliveryPlatform;
  },
  tokenConfiguration: (state) => {
    return state.tokenConfiguration;
  },
  tokenConfigurationIsActive: (state) => {
    return state.tokenConfiguration.active;
  },
};

const actions = {
  loadConfigurations({ commit }) {
    DeliveryPlatformAPI.getConfiguration(state.deliveryPlatform).then(
      (response) => {
        if (response.getErrors([]).length === 0) {
          const responseData = response.getData({
            credentials: {},
            active: false,
          });
          const { active, webhookUrl } = responseData;
          const { triggerToken, apiToken } = responseData.credentials || {};

          if (apiToken || triggerToken || active) {
            commit('setTokenConfiguration', {
              apiToken,
              triggerToken,
              webhookUrl,
              active,
            });
            commit('setTokenConfigurationIsActive', active);
          } else {
            commit('setTokenConfiguration', {
              apiToken: '',
              triggerToken: '',
              webhookUrl,
              active: false,
            });
            commit('setTokenConfigurationIsActive', false);
          }
        }
      }
    );
  },
  cleanConfigurations({ commit }) {
    commit('setTokenConfiguration', null);
    commit('setTokenConfigurationIsActive', false);
  },
};

const mutations = {
  setTokenConfiguration(state, data) {
    state.tokenConfiguration = data;
  },
  setTokenConfigurationIsActive(state, data) {
    state.tokenConfigurationIsActive = data;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
