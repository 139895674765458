// import vttoService from '@/api/order'

const state = {
  oOrder: {
    order_id: null,
    nf: {
      print: true,
      document_type: 'cpf',
      document_number: ''
    },
    delivery_address: {
      origin: 'delivery',
      address_id: 0,
      total_delivery: 50
    },
    items: [],
    auto_close: true,
    payments: [],
    coupon: '',
    order_comments: '',
    subtotal: 0,
    total_discount: 0
  }
}

const getters = {}

const actions = {
  setOrderInfo ({ commit }, oOrderInfo) {
    commit('updateOrder', oOrderInfo)
  },
  mktplcUpdateOrder ({ commit }, data) {
    vttoService.mktplcUpdateOrder(data).then(res => {
      // console.log('res')
      // console.log(res)
    })
  }
}

const mutations = {
  /* Atualiza o objeto de pedido na propriedade especificada e no indice correto */
  updateOrder (state, oOrderInfo) {
    delete state.oOrder[oOrderInfo.sProperty]
    state.oOrder[oOrderInfo.sProperty] = oOrderInfo.oInfo
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
