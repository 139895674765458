import ScheduleAPI from '@/api/schedule';
import OrderAPI from '@/api/order';

const state = {
  newOrders: [],
  alreadyManipulatedOrders: [],
  postponedOrders: [],
  newChangeOrderStatus: {},
  cancelChangeOrderStatus: {},
  newEditedOrder: {},
  newAcceptedOrder: {},
  newCanceledOrder: {},
  newChangeOrderDelivery: {},
};

const getters = {
  newOrders: (state) => {
    return state.newOrders || [];
  },
  postponedOrders: (state) => {
    return state.postponedOrders || [];
  },
};

const actions = {
  getPostponedOrders({ commit }) {
    ScheduleAPI.getAllByType('postponedOrders').then((scheduleResponse) => {
      const postponedOrders = scheduleResponse
        .getData([])
        .filter((schedule) => schedule.status === 0)
        .map((schedule) => String(schedule.data.id));

      commit('setPostponedOrders', postponedOrders);
    });
  },
  setSocketResponse({ commit }, response) {
    return commit('setResponse', response);
  },
  setNewChangeOrderStatus({ commit }, response) {
    return commit('setNewChangeOrderStatus', response.data);
  },
  setCancelChangeOrderStatus({ commit }, response) {
    return commit('setCancelChangeOrderStatus', response.data);
  },
  setNewAcceptedOrder({ commit }, response) {
    return commit('setNewAcceptedOrder', response.data);
  },
  setNewEditedOrder({ commit }, response) {
    return commit('setNewEditedOrder', response.data);
  },
  setNewCanceledOrder({ commit }, response) {
    return commit('setNewCanceledOrder', response.data);
  },
  setPostponedOrder({ commit }, response) {
    return commit('setPostponedOrder', String(response.data.id));
  },
  // Remove o pedido do array de pedidos adiados e adiciona a fila de novos pedidos
  removePostponedOrder({ commit }, response) {
    commit('removePostponedOrder', response.data.id);
    OrderAPI.get(response.data.id).then((res) => {
      const { order } = res.getData({});
      // Somente adiciona o pedido caso ele estja com status em aberto
      if (order.status === 1) {
        return commit('setNewOrder', response);
      }
    });
  },
  setNewChangeOrderDelivery({ commit }, response) {
    return commit('setNewChangeOrderDelivery', response.data);
  },
};

const mutations = {
  setResponse(state, response) {
    state[response.notification] = response;
  },
  setNewOrder(state, response) {
    state.newOrders = response;
  },
  setNewChangeOrderStatus(state, response) {
    state.newChangeOrderStatus = response;
  },
  setCancelChangeOrderStatus(state, response) {
    state.cancelChangeOrderStatus = response;
  },
  setNewAcceptedOrder(state, response) {
    state.newAcceptedOrder = response;
  },
  setNewEditedOrder(state, response) {
    state.newEditedOrder = response;
  },
  setNewCanceledOrder(state, response) {
    state.newCanceledOrder = response;
  },
  setPostponedOrder(state, orderId) {
    state.postponedOrders = Array.from(
      new Set([...state.postponedOrders, orderId])
    );
  },
  setPostponedOrders(state, ordersId) {
    state.postponedOrders = Array.from(new Set(ordersId));
  },
  removePostponedOrder(state, orderId) {
    state.postponedOrders = state.postponedOrders.filter(
      (postponedOrderId) => postponedOrderId != orderId
    );
  },
  setNewChangeOrderDelivery(state, response) {
    state.newChangeOrderDelivery = response;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
