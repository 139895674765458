const state = {
    campaigntypes: {
        "last30days": {
            icon: "fa-gift-card",
            description: "Recupere clientes que estão inativos a muito tempo, é muito importante não dexar eles escaparem."
        },
        "anniversary": {
            icon: "fa-birthday-cake",
            description: "Presenteie seus clientes no dia deles, tudo isso de forma automática e recorrente, não deixe pra depois."
        },
        "custom": {
            icon: "fa-calendar-exclamation",
            description: ""
        }
    }
}

const getters = {}

const actions = {

}

const mutations = {

}

export default {
namespaced: true,
state,
getters,
actions,
mutations
}
  